html,body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}
.no-focus-outline a:focus,
.no-focus-outline button:focus {
  outline: none;
}
#root {
  height: 100%;
}
/* Play with this to see what one you like the best. */
/* This scrollbar is for firefox. Other is for the rest. */
html {
  scrollbar-width: normal;
  scrollbar-color: #5e5e5e #2d2d2d;
  scroll-behavior: smooth;
}
button {
  -webkit-tap-highlight-color: transparent
}
a {
  -webkit-tap-highlight-color: transparent
}
label {
  -webkit-tap-highlight-color: transparent
}

/* This makes it so if users don't want animations, it turns them all off */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.00s !important;
    transition-duration: 0.00s !important;
    scroll-behavior: auto !important;
    }
}
/* .skip-nav is for tabbing and accessibility purposes */
.skip-nav-link {
  position: absolute;
  padding: .5rem 1.5rem;
  border-radius: 0 0 0.25rem 0.25rem;
  left: 0.5rem;
  transform: translateY(-120%);
  transition: transform 325ms ease-in;
}
.skip-nav-link:focus {
  transform: translateY(0)
}
::-webkit-scrollbar {
  width: 1em;
}
::-webkit-scrollbar-thumb {
  /* The 5 makes it slightly less transparent */
  background-color: #5e5e5e7e;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #5e5e5e;
} 
::-webkit-scrollbar-track {
  background-color: #2d2d2d71;
}
::-webkit-scrollbar-track {
  background-color: #2d2d2d;
}
.teko-font {
  font-family: 'Teko', sans-serif;
}
.data-array-items li:not(:last-child) {
  border-bottom: none
}
.modal-content {
  overflow: auto;
  outline: none;
  padding: 12px;
  margin-left: auto;
  margin-right: auto;
}
.modal-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0,0,0,0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-right: 6px;
  padding-left: 6px;
}
/* Array Modal styles */
.array-modal-content {
  overflow: auto;
  outline: none;
  padding: 12px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
}
.array-modal-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0,0,0,0.5);
  overflow: auto;
  padding-right: 6px;
  padding-left: 6px;
  margin-right: 16px;
}

.unclickable-overlay {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
}
/* iframe */
.i-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 ratio */
  height: 0;
}
.standard-iframe {
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
/* Spotify iframe */
.spotify-iframe {
  width: 100%;
  height: 232px;
}
.spotify-iframe-album {
  width: 100%;
  height: 152px;
}
/* Instagram iframe */
.instagram-container {
  border-radius: 1.25rem;
  width: 100%; 
  margin-right: -12px;
  margin-left: -12px;
  max-width: fit-content;
  overflow: hidden;
  box-shadow:  0px 0px var(--tiktok-one) var(--tiktok-two) var(--member-box-shadow);
}
.instagram-iframe {
  height: 583px; 
  width: 100%;
}
/* Facebook */
.facebook-container {
  border-radius: 1.25rem;
  left: 0; 
  width: 100%; 
  position: relative; 
  padding-bottom: 56%;
  margin-left: auto;
  margin-right: auto;
}
.facebook-iframe {
  position: absolute; 
  border-radius: 1.25rem;
  height: 100%;
  width: 100%;
  box-shadow:  0px 0px var(--tiktok-one) var(--tiktok-two) var(--member-box-shadow);
}
/* Twitter iframe */
/* .twitter-container {
  width: 100%; 
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}
.twitter-iframe {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: 263px;
} */
/* Soundcloud iframe */
.soundcloud-iframe {
  width: 100%;
  height: auto;
}

/* Tiktok iframe */
.tiktok-container {
  border-radius: 1.25rem;
  max-width: 320px;
  margin-right: -12px;
  margin-left: -12px;
  overflow: hidden;
  min-width: 320px;
  max-height: 735px;
  width: 100%;
  box-shadow:  0px 0px var(--tiktok-one) var(--tiktok-two) var(--member-box-shadow);
}

@media (min-width: 359px) {
  .tiktok-container {
    margin-right: auto;
    margin-left: auto;
  }
  .instagram-container {
    margin-right: auto;
    margin-left: auto;
  }
}
.tiktok-iframe {
  overflow: hidden;
  width: 100%;
  height: 739px;
  visibility: unset;
  margin-top: -1px;
}
/* Custom margin and padding */
.-right-0 {
  right:0;
}
.max-w-285px {
  width: 285px;
}
.right-minus-40px {
  right: -40px;
}
.right-minus-10px {
  right: -10px;
}
.right-minus-30px {
  right: -30px;
}
.right-minus-17px {
  right: -17px;
}
.right-theme {
  right: 11px;
}
.w-47px {
  width: 47px;
}
.max-h-80 {
  max-height: 75vh;
}
.fit-content {
  height: fit-content
}
.mr-54px {
  margin-right: auto
}
.intro-modal-margin {
  margin-right: 0px;
  margin-left: 0px;
}
@media (min-width: 340px) {
  .intro-modal-margin {
    margin-right: -8px;
    margin-left: -8px;
  }
}
@media (min-width: 914px) {
  .mr-54px {
    margin-right: 3.375rem
  }
}
.px-10px {
  padding-left: 10px;
  padding-right: 10px;
}
.py-6px {
  padding-top: 6px;
  padding-bottom: 6px;
}
.p-2px {
  padding: 2px;
}
.mt-10px {
  margin-top: 10px;
}
.mr-11px {
  margin-right: 11px;
}
.-mb-7px {
  margin-bottom: -7px;
}
.pb-3px {
  padding-bottom: 3px;
}
.ml-6px {
  margin-left: 6px;
}
.-mr-6px {
  margin-right: -6px;
}
.mr-6px {
  margin-right: 6px;
}
.mt-14px {
  margin-top: 14px;
}
.pt-5px {
  padding-top: 5px;
}
.pt-2px {
  padding-top: 2px;
}
.pb-2px {
  padding-bottom: 2px;
}
.py-2px {
  padding-bottom: 2px;
  padding-top: 2px;
}
.h-50px {
  height: 50px;
}
.mb-30px {
  margin-bottom: 30px;
}
.h-14px {
  height: 14px;
}
.h-17px {
  height: 17px;
}
.h-18px {
  height: 18px;
}
.mt-3px {
  margin-top: 3px;
}
.-mt-3px {
  margin-top: -3px;
}
.-mt-1px {
  margin-top: -1px;
}
.-ml-px {
  margin-left: -1px;
}
.-mt-2px {
  margin-top: -2px;
}
.-mt-7px {
  margin-top: -7px;
}
.mt-2px {
  margin-top: 2px;
}
.pt-2px {
  padding-top: 2px;
}
.mb-5px {
  margin-bottom: 5px;
}
.w-34 {
  width: 8.5rem;
}
.-mr-2px {
  margin-right: -2px;
}
.mr-2px {
  margin-right: 2px;
}
.mb-2px {
  margin-bottom: 2px;
}
.px-6px {
  padding-left: 6px;
  padding-right: 6px;
}
.mr-5px {
  margin-right: 5px;
}
.min-width-22px {
  min-width: 22px;
}
.min-width-28px {
  min-width: 28px;
}
.my-2px {
  margin-top: 2px;
  margin-bottom: 2px;
}
.my-6px {
  margin-top: 6px;
  margin-bottom: 6px;
}
.px-28px {
  padding-left: 28px;
  padding-right: 28px;
}
.my-3px {
  margin-top: 3px;
  margin-bottom: 3px;
}
.mx-2px {
  margin-left: 2px;
  margin-right: 2px;
}
.mr-3px {
  margin-right: 3px;
}
.pb-6px {
  padding-bottom: 6px;
}
.post-detail-title {
  font-size: 2rem;
}
@media (min-width: 640px) {
  .post-detail-title {
    font-size: 2.5rem;
  }
}
.max-w-12rem {
  max-width: 12rem;
}
.following-card-width {
  width: 12rem;
}
@media (min-width: 328px) {
  .following-card-width {
    width: 8rem;
  }
}
@media (min-width: 380px) {
  .following-card-width {
    width: 9rem;
  }
}
@media (min-width: 456px) {
  .following-card-width {
    width: 12rem;
  }
}
/* Various custom .css */
.opacity-90 {
  opacity: 0.9;
}
.-mt-14px {
  margin-top: -14px;
}
.text-underline-under, u {
  text-underline-position: under;
} 
.whitespace-nowrap {
  white-space: nowrap;
}
.-mt-50px {
  margin-top: -50px
}
.h-7 {
  height: 28px;
}
.min-w-11 {
  min-width: 2.75rem;
}
.min-w-9 {
  min-width: 2.25rem;
}
.min-w-8 {
  min-width: 2rem
}
.min-w-200px {
  min-width: 200px;
}
.rounded-2xl {
  border-radius: 2.25rem;
}
.border-radius-px {
  border-radius: 1px;
}
.category-font-size {
  font-size: 16px;
}
.flex-basis-auto {
  flex-basis: auto;
}
.hover-rounded:hover {
  border-radius: .25rem;
}
.hover-rounded-lg-t:hover {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.hover-rounded-lg-b:hover {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}
.hover-rounded-xl-t:hover {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}
.hover-rounded-xl-b:hover {
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}
.-ml-2px {
  margin-left: -2px;
}
.ml-3px {
  margin-left: 3px;
}
.height-148px {
  height: 148px;
}
.height-14px {
  height: 14px;
}
.text-size-header {
  font-size: 3rem;
  margin-bottom: 4px;
}
.pl-7 {
  padding-left: 1.75rem;
}
button:disabled:hover {
  box-shadow: none;
}
@media (min-width: 370px) {
  .text-size-header {
    font-size: 4rem;
    margin-bottom: 0px;
  }
}
@media (min-width: 640px) {
  .text-size-header {
    font-size: 3.5rem;
    margin-bottom: 0px;
  }
}
@media (min-width: 914px) {
  .text-size-header {
    font-size: 3rem;
    margin-bottom: 0px;
  }
}
@media (min-width: 945px) {
  .text-size-header {
    font-size: 3.5rem;
    margin-bottom: 0px;
  }
}
/* For Dropdown */
.-ml-35px {
  margin-left: -35px;
}
.ml-2px {
  margin-left: 2px;
}
.mt-3px {
  margin-top: 3px;
}
.mt-5px {
  margin-top: 5px;
}
.mt-6px {
  margin-top: 6px;
}
.menu-rounded {
  border-radius: 2.25rem;
}
.menu-font-size {
  font-size: 1.75rem;
}
.menu-height {
  height: 3rem;
}
/* Use for <hr>'s */
.outsetBorder {
  border-style: outset;
}
.word-wrap {
  word-wrap: break-word;
}
.notification-paragraph p {
  font-size: 0.75rem;
}
.border-bottom-radius-10px {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
/* CodeMirror */
.CodeMirror {
  height: auto;
}

select:active, select:hover {
  outline: none
}

.custom-input-container {
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  color: var(--custom-input-container);
}
.custom-input-container::after {
  background: var(--custom-input-container-two);
  content: attr(data-name);
  font-size: 18px;
  height: 19px;
  left: 12px;
  line-height: 17px;
  padding: 0 2px;
  position: absolute;
  top: -8.5px;
  border-radius: 4px;
}

.custom-input {
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.7;
  padding: 13px 10px 9px;
  transition: border-color .2s;
  width: 100%;
  box-shadow: 0px 0px 2px 0px #1b1b1b; 
}

/* Text colors */
.text-link {color : #007bff}
.text-brand-green {color: #2BA228}
.text-dark-green {color: #195D1D}
.text-light-gray {color: #D8D8D8}
.text-medium-gray {color: #5E5E5E}
.text-medium-gray-lighter {color: #8f8f8f}
.text-medium-gray-darker {color: #2d2d2d}
.text-darkest-gray {color: #1B1B1B}
.text-body-background {color: #121212}
.text-soft-black {color: #030303}
.text-input-active {color: #979797}
.text-input-disabled {color: #515356}
.text-input-background {color: #39393A}
.text-alert-success {color: #FF3608}
.text-gold {color: #d4af37}
.text-white-gray {color: #e8e8e8}
.text-dark-blue {color: #000dad}
.text-medium-blue {color: #0400e0}

/* Background colors */
.bg-brand-green {background-color: #2BA228}
.bg-dark-green {background-color: #195D1D}
.bg-light-gray {background-color: #D8D8D8}
.bg-medium-gray {background-color: #5E5E5E}
.bg-medium-gray-darker {background-color: #2d2d2d}
.bg-darkest-gray {background-color: #1B1B1B}
.bg-body-background {background-color: #121212}
.bg-soft-black {background-color: #030303}
.bg-input-active {background-color: #979797}
.bg-input-disabled {background-color: #515356}
.bg-input-background {background-color: #39393A}
.bg-alert-success {background-color: #FF3608}
.bg-modal-border {background-color: #444}
.bg-mack-the-knife {background-color: #444}
.bg-gold {background-color: #d4af37}
.bg-white-gray {background-color: #e8e8e8}
.bg-dark-blue {background-color: #000dad}
.bg-medium-blue {background-color: #0400e0}

/* Shadows */
.shadowEffect {box-shadow: -1px -2px 3px 0px #5e5e5e}
.shadowWhite {box-shadow: 0px 0px 4px 1px #fff;}
.containerShadowLight {box-shadow: 0px 0px 4px 1px #1b1b1b;}
.containerShadowSmaller {box-shadow: 0px 0px 4px 0px #5E5E5E;}
.containerShadowDark {box-shadow: 0px 0px 4px 1px #5e5e5e;}
.profileShadowDarkMode {box-shadow: 0px 0px 6px 2px #fff;}
.profileShadowLightMode {box-shadow: 0px 0px 10px 3px #1b1b1b;}

/* ripple button */
.button-ripple {
  position: relative;
  overflow: hidden;
  transition: background-color 300ms;
}
span.ripple {
  position: absolute; /* The absolute position we mentioned earlier */
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}
@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

/* Borders */
.date-border {
  border-color: #5E5E5E;
  border-radius: .25rem;
  border-left-width: 1px;
  border-right-width: 1px;
  width: intrinsic;           /* Safari/WebKit uses a non-standard name */
  width: -moz-max-content;    /* Firefox/Gecko */
  width: -webkit-max-content;
}
.w-max {
  width: intrinsic;           /* Safari/WebKit uses a non-standard name */
  width: -moz-max-content;    /* Firefox/Gecko */
  width: -webkit-max-content;
}
@media (min-width: 914px) {
  .w-max {
    width: 100%;
    padding-right: -8px;
    padding-left: -8px;
  }
}
.w-max-no-query {
  width: intrinsic;           /* Safari/WebKit uses a non-standard name */
  width: -moz-max-content;    /* Firefox/Gecko */
  width: -webkit-max-content;
}
.text-shadow-none {
  text-shadow: none;
}
.lightBorder {
  border-color: #828282;
  border-radius: .375rem;
}
.table-fill {
  background-color: #828282;
}
.tableBorder {
  border-color: #828282;
}
.customInnerShadow {
  box-shadow: inset 0 0 4px #5E5E5E;
}
.border-medium-gray {
  border-color: #5E5E5E;
  border-radius: .25rem;
  border-width: 1px;
}
.border-dark-green {
  border-color: #195D1D;
  border-radius: .25rem;
  border-width: 1px;
}
.border-white {
  border-color: white;
  border-radius: .25rem;
  border-width: 1px;
}
.border-brand-green {
  border-color: #2BA228;
}
.border-darkest-gray {
  border-color: #1B1B1B;
  border-radius: .25rem;
  border-width: 1px;
}
.border-black {
  border-color: #000000;
  border-radius: .25rem;
  border-width: 1px;
}
.border-white-gray {
  border-color: #e8e8e8;
  border-radius: .25rem;
  border-width: 1px;
}
.border-c9 {
  border-color: #c9c9c9;
}
/* .notification-position {
   position: relative;
}
.notification-position::before {
  content: attr(data-content);
  position: absolute;
  bottom: -10px;
  right: -8px;
  padding: 1px;
  color: red;
  border-radius: 1.25rem;
  background:rgba(0, 0, 0, 0.7)
} */
.flip-hover {
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 2s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: 2s;
  transition: transform 2s;
  transform-style: preserve-3d;
}
.total-members-animation {
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 4s;
  transition: transform infinite linear;
  transform-style: preserve-3d;
  animation: rotateYMem 3s 1 linear;
}
@-webkit-keyframes rotateYMem {
  0% { transform: rotateY(0deg); }
  100% { transform: rotateY(360deg); }
}
.flip-hover:hover {
  animation: rotateY 2s infinite linear;
}
.text-spin {
  transition: transform infinite linear;
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 3s;
  -moz-transition-property: -moz-transform;
  -moz-transition-duration: 3s;
  transform-style: preserve-3d;
}
.text-spin:hover {
  transform: rotateY(360deg);
  transition-delay: .3s;
}
@-webkit-keyframes rotateY {
  0% {
    -webkit-transform: rotateY(0deg) scale(var(--scale-value));
  }
  25% {
    -webkit-transform: rotateY(90deg) scale(var(--scale-value));
  }
  50% {
    -webkit-transform: rotateY(180deg) scale(var(--scale-value));
  }
  75% {
    -webkit-transform: rotateY(270deg) scale(var(--scale-value));
  }
  100% {
    -webkit-transform: rotateY(360deg) scale(var(--scale-value));
  }
}
@-moz-keyframes rotateY {
  0% {
    -moz-transform: rotateY(0deg) scale(var(--scale-value));
  }
  25% {
    -moz-transform: rotateY(90deg) scale(var(--scale-value));
  }
  50% {
    -moz-transform: rotateY(180deg) scale(var(--scale-value));
  }
  75% {
    -moz-transform: rotateY(270deg) scale(var(--scale-value));
  }
  100% {
    -moz-transform: rotateY(360deg) scale(var(--scale-value));
  }
}
@keyframes rotateY {
  0% {
    transform: rotateY(0deg) scale(var(--scale-value));
  }
  25% {
    transform: rotateY(90deg) scale(var(--scale-value));
  }
  50% {
    transform: rotateY(180deg) scale(var(--scale-value));
  }
  75% {
    transform: rotateY(270deg) scale(var(--scale-value));
  }
  100% {
    transform: rotateY(360deg) scale(var(--scale-value));
  }
}
[data-quill="BUBBLE"] { 
  --border-radius-one: .375rem;
  --border-radius-two: 1.25rem;
}
[data-quill="SNOW"] { 
  --border-radius-one: 0rem;
  --border-radius-two: 0px;
}
/* Light/Dark mode colors when Redux class switching isn't possible */
[data-theme="DARK_THEME"] {
  --first-text: #d8d8d8;
  --second-text: #2BA228;
  --third-text: #D8D8D8;
  --fifth-text: #2BA228;
  --sixth-text: #195D1D;
  --eighth-text: #fff;
  --ninth-text: #2ba228;
  --tenth-text: #a7a7a7;
  --eleventh-text: #fff;
  --thirteenth-text: #fff;
  --fifth-background: #1b1b1b;
  --eighth-background: #121212;
  --fourth-text-hover: #2BA228;
  --fifth-hover: #2BA228;
  --fifth-hover-bold: 400;
  --sixth-hover: #2BA228;
  /* --custom-input-container: transparent; */
  --custom-input-container-two: #1b1b1b;
  --shadow-effect-five: 0px 0px 4px 1px #5e5e5e;
  --shadow-effect-six: 0px 0px 4px 1px #fff;
  --profile-member-shadow: 0px 0px 6px 2px #fff;
  --second-border: rgba(43, 162, 40, .2);
  --sixth-border: #5E5E5E;
  --eighth-border: #121212;
  --member-bg-color: #313131;
  --member-bg-color-two: #030303;
  --member-box-shadow: #fff;
  --member-shadow-size: 0px;
  --total-member-bg-color-one: #313131;
  --total-member-bg-color-two: #030303;
  --total-member-bg-color-three: #313131;
  --bg-color-one: #313131;
  --bg-color-two: #030303;
  --bg-color-three: #3e3e3e;
  --bg-color-four: #313131;
  --bg-color-five: #030303;
  --bg-color-six: #313131;
  --bg-color-seven: #030303;
  --bg-color-eight: #313131;
  --total-member-shadow-size: 0px;
  --bg-hover: #2ba228;
  --numbers-color: #8d8d8d;
  --color-hover: #fff;
  --bg-red-hover: #c53030;
  --bg-orange-hover: #c05621;
  --tooltip-font-color: #fff;
  --editor-font-color: #fff;
  --highlight-border-color: #2ba228;
  --inner-border-color: #5e5e5e;
  --highlight-border-size: 12px;
  --shadow-effect-two: 0px 0px 2px 0px #5e5e5e;
  --shadow-effect-eight: 0px 0px 3px 1px #202020;
  --hover-inset-one: 12px;
  --hover-inset-two: 12px;
  --hover-inset-three: 10px;
  --orange-button: var(--bg-color-eight) 40%, var(--bg-color-seven), var(--bg-color-eight);
  --red-button: var(--bg-color-eight) 40%, var(--bg-color-seven), var(--bg-color-eight);
  --green-button: var(--bg-color-eight) 40%, var(--bg-color-seven), var(--bg-color-eight);
  --green-button-shadow: inset 0 0 0 0 transparent;
  --orange-button-shadow: inset 0 0 0 0 transparent;
  --red-button-shadow: inset 0 0 0 0 transparent;
  --highlight-size-one: 2px;
  --highlight-size-two: 2px;
  --highlight-size-three: 4px;
  --image-shadow-size: 2px;
  --hover-size: 3px;
  --logo-color: #444;
  --post-list-color: #444;
  --bold-or-not: 500;
  --rating-highlight-bg: #000;
  --rating-highlight-color: #818181;
  --gradient-two-setup: var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one);
  --gradient-description-setup: var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one);
  --gradient-description-two-setup: var(--bg-color-four), var(--bg-color-five);
  --gradient-six-setup: var(--total-member-bg-color-one), var(--total-member-bg-color-two) 35%, var(--total-member-bg-color-one);
  --gradient-stats-setup: var(--bg-color-three), var(--fifteenth-background) 70%;
  --gradient-stats-setup-two: var(--fifteenth-background) 30%, var(--bg-color-three);
  --gradient-seven-setup: #fff 50%, #c4c4c4;
  --gradient-eight-setup: #f3f3f3 50%, #ededed, #f3f3f3 50%, #ededed;
  --gradient-nine-setup: #d8d8d8 0%, #fff, #c7c7c7 67%;
  --gradient-ten-setup: var(--bg-color-four), var(--bg-color-five);
  --gradient-eleven-setup: #737373, var(--total-member-bg-color-two) 35%, var(--total-member-bg-color-one);
  --gradient-twelve-setup: #737373, var(--total-member-bg-color-two) 35%, var(--total-member-bg-color-one);
  --gradient-thirteen-setup: #737373, var(--total-member-bg-color-two) 35%, var(--total-member-bg-color-one);
  --focus-border: #2ba228;
  --selection-background: #d8d8d8;
  --selection-color: #030303;
  --inner-shadow: black;
  --profile-shadow-input: #1c1c1c;
  --hr-border-dropdown: #999;
  --arrow-color: #999;
  --quill-border-color: #242424;
  --dropdown-background: #343434;
  --description-font-color: #fff;
  --href-link: #2ba228;
  --big-shadow: 6px;
  --menu-big-shadow: 6px;
  --tiktok-one: 4px;
  --tiktok-two: 1px;
  --skeleton-one: #5e5e5e;
  --skeleton-two: #d8d8d8;
  --label-hover: #40a53e;
  --radio-checked: #245523;
  --fifteenth-background: #1b1b1b;
  --background-fourteen: #121212;
  --post-detail-background: #212121;
  --standard-background: #1b1b1b;
  /* 245523 */
}
[data-theme="LIGHT_THEME"] {
  --first-text: #1B1B1B;
  --second-text: #fff;
  --third-text: #202020;
  --fifth-text: #00a90a;
  --sixth-text: #000;
  --eighth-text: #1b1b1b;
  --ninth-text: #000;
  --tenth-text: #1B1B1B;
  --eleventh-text: #202020;
  --thirteenth-text: #000;
  --fifth-background: #fff;
  --eighth-background: #d8d8d8;
  --fourth-text-hover: #00a90a;
  /* --fifth-hover: transparent; */
  --fifth-hover-bold: 700;
  --sixth-hover: #000;
  --custom-input-container: #696969;
  --custom-input-container-two: #fff;
  --shadow-effect-five: 0px 0px 4px 1px #1b1b1b;
  --shadow-effect-six: 0px 0px 4px 1px #1b1b1b;
  --profile-member-shadow: 0px 0px 10px 3px #1b1b1b;
  --second-border: rgba(216, 216, 216, .7);
  --sixth-border: #D8D8D8;
  --eighth-border: #030303;
  --member-bg-color: #666666;
  --member-bg-color-two: #030303;
  --member-box-shadow: #1b1b1b;
  --member-shadow-size: 3px;
  --total-member-bg-color-one: #D8D8D8;
  --total-member-bg-color-two: #fff;
  --total-member-bg-color-three: #dadada;
  --bg-color-one: #ececec;
  --bg-color-two: #fff;
  --bg-color-four: #fff;
  --bg-color-five: #efefef;
  --bg-color-six: #bbbbbb;
  --bg-color-seven: #fff;
  --bg-color-eight: #797979;
  --bg-color-nine: #d8d8d8;
  --total-member-shadow-size: 1px;
  --bg-hover: #121212;
  --numbers-color: #121212;
  --color-hover: #fff;
  --bg-red-hover: #c53030;
  --bg-orange-hover: #c05621;
  --tooltip-font-color: #1B1B1B;
  --editor-font-color: #666;
  --highlight-border-color: #1B1B1B;
  --inner-border-color: #1b1b1b;
  --highlight-border-size: 6px;
  --shadow-effect-two: 0px 0px 2px 0px #1b1b1b;
  --shadow-effect-eight: 0px 0px 3px 1px #ababab;
  --hover-inset-one: 4px;
  --hover-inset-two: 12px;
  --hover-inset-three: 8px;
  --orange-button: #f6dacc, var(--bg-orange-hover) 40%;
  --red-button: #ffa0a0, var(--bg-red-hover) 40%;
  --green-button: #8cff8b, #2ba228 40%;
  --green-button-shadow: inset 0 0px 8px 0px #2ba228;
  --orange-button-shadow: inset 0 0px 8px 0px var(--bg-orange-hover);
  --red-button-shadow: inset 0 0px 8px 0px var(--bg-red-hover);
  --highlight-size-one: 2px;
  --highlight-size-two: 2px;
  --highlight-size-three: 2px;
  --image-shadow-size: 4px;
  --hover-size: 2px;
  --logo-color: #bbbbbb;
  --post-list-color: #d8d8d8;
  --bold-or-not: 600;
  --gradient-two-setup: var(--bg-color-four), var(--bg-color-five);
  --gradient-description-setup: #fff 50%, #fdfdfd, #fff 50%, #fdfdfd;
  --gradient-description-two-setup: #fff 50%, #eeeeee;
  --gradient-six-setup: var(--total-member-bg-color-one) 0%, var(--total-member-bg-color-two), var(--total-member-bg-color-one) 67%;
  --gradient-stats-setup: var(--bg-color-nine), var(--bg-color-two) 30%;
  --gradient-stats-setup-two: var(--bg-color-two) 70%, var(--bg-color-nine);
  --gradient-seven-setup: #fff 50%, #e4e4e4;
  --gradient-eight-setup: #f9f9f9 50%, #fdfdfd, #f9f9f9 50%, #fdfdfd;
  --gradient-nine-setup: #f9f9f9 50%, #fdfdfd, #f9f9f9 50%, #fdfdfd;
  --gradient-ten-setup: #ededed, var(--bg-color-two) 30%, #ededed;
  --gradient-eleven-setup: #fff, #ffffff 30%, #dadada 50%;
  --gradient-twelve-setup: var(--bg-color-four), var(--bg-color-five);
  --gradient-thirteen-setup: #fff, #ffffff 30%, #e7e7e7 67%;
  --rating-highlight-bg: #000;
  --rating-highlight-color: #979797;
  --focus-border: #797979;
  --selection-background: #030303;
  --selection-color: #D8D8D8;
  --inner-shadow: #D8D8D8;
  --profile-shadow-input: #d8d8d8;
  --hr-border-dropdown: #bbb;
  --arrow-color: #000000;
  --quill-border-color: #d8d8d8;
  --dropdown-background: #d4d4d4;
  --description-font-color: #000000;
  --href-link: #0093fb;
  --big-shadow: 32px;
  --menu-big-shadow: 32px;
  --tiktok-one: 6px;
  --tiktok-two: 2px;
  --skeleton-one: #d8d8d8;
  --skeleton-two: #fff;
  --label-hover: rgb(214, 255, 214);
  --radio-checked: rgb(164, 255, 164);
  --fifteenth-background: #fff;
  --background-fourteen: #fdfdfd;
  --post-detail-background: #fdfdfd;
  --standard-background: #ffffff ;
}
.colored-dots ul li::before {
  color: var(--fifth-text);
  content: '\2022'; /* Unicode character for a bullet point */
  margin-right: 0.5em; /* Adjust the spacing between the bullet and the text as needed */
}
.colored-dots ul {
  list-style: none;
  margin-left: 0em;
}
.shadow-effect-five {
  box-shadow: var(--shadow-effect-five)
}
.shadow-effect-six {
  box-shadow: var(--shadow-effect-six)
}
.profile-member-shadow {
  box-shadow: var(--profile-member-shadow)
}
.second-border {
  border-color: var(--second-border)
}
.sixth-border {
  border-color: var(--sixth-border)
}
.eighth-border {
  border-color: var(--eighth-border)
}
.first-text {
  color: var(--first-text)
}
.second-text {
  color: var(--second-text)
}
.third-text {
  color: var(--third-text)
}
.fifth-text {
  color: var(--fifth-text)
}
.fifth-hover:hover {
  color: var(--fifth-hover);
  font-weight: var(--fifth-hover-bold);
}
.sixth-text {
  color: var(--sixth-text)
}
.sixth-hover:hover {
  color: var(--sixth-hover);
}
.eighth-text {
  color: var(--eighth-text)
}
.ninth-text {
  color: var(--ninth-text)
}
.tenth-text {
  color: var(--tenth-text)
}
.eleventh-text {
  color: var(--eleventh-text)
}
.thirteenth-text {
  color: var(--thirteenth-text)
}
.fourth-text-hover:hover {
  color: var(--fourth-text-hover)
}

.fifth-background {
  background-color: var(--fifth-background)
}
.seventh-background {
  background-color: #E4E2E2;
}
.eighth-background {
  background-color: var(--eighth-background)
}
.standard-background {
  background-color: var(--standard-background)
}
.post-detail-background {
  background-color: var(--post-detail-background);
}
.fifteenthBackground {
  background-color: var(--fifteenth-background);
}
.background-fourteen {
  background-color: var(--background-fourteen);
}
.arrow-color {
  color: var(--arrow-color)
}
/* Radio Buttons */
.radio-container input[type="radio"], .radio-container-two input[type="radio"]  {
  opacity: 0;
  position: fixed;
  width: 0;
}
.radio-container label {
  background: -ms-linear-gradient(var(--gradient-eleven-setup));
  background: -webkit-linear-gradient(var(--gradient-eleven-setup));
  background: -moz-linear-gradient(var(--gradient-eleven-setup));
  background: -o-linear-gradient(var(--gradient-eleven-setup));
  background: linear-gradient(var(--gradient-eleven-setup));  
  padding: 10px 20px;
  border: 2px solid #444;
}
.radio-container-two label {
  background: -ms-linear-gradient(var(--gradient-eleven-setup));
  background: -webkit-linear-gradient(var(--gradient-eleven-setup));
  background: -moz-linear-gradient(var(--gradient-eleven-setup));
  background: -o-linear-gradient(var(--gradient-eleven-setup));
  background: linear-gradient(var(--gradient-eleven-setup));  
  padding: 4px 10px;
  border: 2px solid #444;
}
.radio-container label:hover, .radio-container-two label:hover {
  background: var(--label-hover);
}

.radio-container input[type="radio"]:focus + label, .radio-container-two input[type="radio"]:focus + label {
  border: 2px dashed #444;
}

.radio-container input[type="radio"]:checked + label, .radio-container-two input[type="radio"]:checked + label {
  background: var(--radio-checked);
  border-color: #4c4;
}
.fading {
  position: relative;
}
.fading:after {
  content: '';
  height: 70%;
  width: 100%;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to bottom, transparent, var(--quill-border-color));
}
/* Glossy */
.glossy {
  width: 100%;
  height: 100%;
  filter: blur(.2rem);
  background-color: rgba(223, 223, 223, 0.15);
  opacity: .5;
}
.blur-transition {
  transition: 1s filter linear;
}
.no-gloss {
  filter: blur(0px);
  opacity: 1;
}
.min-height-200px {
  min-height: 200px;
}
/* Skeleton */
.skeleton-card {
  opacity: .5;
}
.skeleton-fake-image {
  width: 22px;
  height: 22px;
}
.skeleton-fake-profile-image {
  width: 85px;
  height: 85px;
}
.skeleton {
  background: linear-gradient(
    120deg,
    var(--skeleton-one) 30%,
    var(--skeleton-two) 38%,
    var(--skeleton-two) 40%,
    var(--skeleton-one) 48%
  );
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: skeleton-loading 2s infinite;
}
.skeleton-text {
  width: 100%;
  height: .5rem;
  margin-bottom: .25rem;
  border-radius: .125rem;
}
.skeleton-text-longer {
  width: 70%;
  height: 1rem;
  border-radius: .125rem;
}
.skeleton-text-large {
  width: 200px;
  height: 1rem;
  border-radius: .125rem;
}
.skeleton-text-300 {
  width: 300px;
  height: 1rem;
  border-radius: .125rem;
}
.skeleton-text-thicker {
  width: 120px;
  height: 2rem;
  border-radius: .125rem;
}
.skeleton-text-title {
  width: 200px;
  height: 2rem;
  border-radius: .125rem;
}
.skeleton-text-minor {
  width: 120px;
  height: 1rem;
  border-radius: .125rem;
}
.skeleton-text-date {
  width: 70px;
  height: 1rem;
  border-radius: .125rem;
}
.skeleton-text-button {
  width: 40px;
  height: 1rem;
  border-radius: .125rem;
}
.skeleton-split {
  width: 80%;
  height: 1rem;
  margin-bottom: .25rem;
  border-radius: .125rem;
}
.width-20-percent {
  width: 20%
}
.skeleton-text:last-child {
  margin-bottom: 0;
  width: 80%;
}
@keyframes skeleton-loading {
  100% {
    background-position: -100% 0;
  }
}
.grey-and-bold {
  color: var(--numbers-color);
}
.faq-custom-color {
  color: var(--highlight-border-color)
}
.description-color {
  color: var(--description-font-color);
}
.hr-border-dropdown {
  border-color: var(--hr-border-dropdown)
}
/* Highlight selections */
::selection {
  background-color: var(--selection-background);
  color: var(--selection-color);
}
.red-notification-button {
  background: -webkit-linear-gradient(#ffa0a0, #c53030 40%);
  background: -moz-linear-gradient(#ffa0a0, #c53030 40%);
  background: -o-linear-gradient(#ffa0a0, #c53030 40%);
  background: linear-gradient(#ffa0a0, #c53030 40%);  
}
.shadow-effect-two {
  box-shadow: var(--shadow-effect-two)
}
/* Radio Buttons for paywall rating */
.rating {
  position: sticky;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}
.rating input {
  display: none;
}
.rating label {
  display: block;
  cursor: pointer;
  width: 40px;
}
.rating label::before {
  content: '\f005';
  font-family: fontAwesome;
  position: absolute;
  top:0;
  display: block;
  font-size: 30px;
  color: var(--rating-highlight-color);
  text-shadow: -2px -2px 1px var(--rating-highlight-bg);
}
.rating label::after {
  content: '\f005';
  font-family: fontAwesome;
  position: absolute;
  display: block;
  font-size: 30px;
  color: #ffff00;
  top: 0;
  opacity: 0;
  transition: .3s;
  text-shadow: -2px -2px 1px var(--rating-highlight-bg);
}
.rating label:hover::after,
.rating label:hover ~ label::after,
.rating input[type="radio"]:checked ~ label::after {
  opacity: 1;
}
.logo-black {
  color: #202020;
}
[data-member='large'] {
  --font-size: 24px;
  --arrow-size: 14px;
  --height: 33px;
  --scale-value: 2;
}
[data-member='standard'] {
  --font-size: 20px;
  --arrow-size: 18px;
  --height: 26px;
  --scale-value: 2;
}
[data-member='profile'] {
  --font-size: 30px;
  --arrow-size: 8px;
  --height: 42px;
  --scale-value: 1;
}
.shadow-effect-one {
  box-shadow: 0px 0px 2px 0px #5e5e5e;;
}
.shadow-effect-eleven {
  box-shadow: 0px 0px 0px 1px var(--profile-shadow-input);
}
.shadow-effect-seven {
  box-shadow: 0px 0px 3px 0px var(--inner-border-color);
}
.shadow-effect-eight {
  box-shadow: var(--shadow-effect-eight);
}
.shadow-effect-nine {
  box-shadow: inset 0 0 5px #666666, 0px 0px 3px 0px var(--inner-border-color);
}
.shadow-effect-ten {
  box-shadow: var(--shadow-effect-eight);
}
.shadow-effect-ten:focus {
  border: 1px solid var(--focus-border);
  box-shadow: inset 0 0 2px black;
}
.highlight-border {
  border-color: var(--highlight-border-color);
  box-shadow: inset 0 0 var(--highlight-border-size) var(--highlight-border-color), 0 0 var(--highlight-size-one) var(--highlight-size-two) var(--highlight-border-color);
}
.highlight-border-dropdown {
  border-color: var(--highlight-border-color);
  box-shadow: inset 0 0px var(--highlight-border-size) var(--highlight-border-color), 0 0 var(--highlight-size-one) var(--highlight-size-two) var(--highlight-border-color);
}
.border-nav {
  border-color: var(--inner-border-color);
}
.highlight-shadow {
  box-shadow: 0 0 4px 1px var(--highlight-border-color);
}
/* Gradient / Button styles */
.gradient-background {
  background: -webkit-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
  background: -moz-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
  background: -o-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
  background: linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
  box-shadow:  0px 0px 4px var(--total-member-shadow-size) var(--member-box-shadow);
}
/* .gradient-background-two {
  background: -webkit-linear-gradient(var(--bg-color-six), var(--bg-color-seven), var(--bg-color-six));
  background: -moz-linear-gradient(var(--bg-color-six), var(--bg-color-seven), var(--bg-color-six));
  background: -o-linear-gradient(var(--bg-color-six), var(--bg-color-seven), var(--bg-color-six));
  background: linear-gradient(var(--bg-color-six), var(--bg-color-seven), var(--bg-color-six));  
}
.gradient-background-two:hover {
  border-color: var(--highlight-border-color);
  box-shadow: inset 0 0 8px var(--highlight-border-color), 0 0 var(--highlight-size-one) 3px var(--highlight-border-color);
} */
.gradient-background-two-hover, .gradient-background-two {
  background: -ms-linear-gradient(var(--gradient-two-setup));  
  background: -webkit-linear-gradient(var(--gradient-two-setup));
  background: -moz-linear-gradient(var(--gradient-two-setup));
  background: -o-linear-gradient(var(--gradient-two-setup));
  background: linear-gradient(var(--gradient-two-setup));  
}
.gradient-background-description {
  background: -ms-linear-gradient(var(--gradient-description-setup));  
  background: -webkit-linear-gradient(var(--gradient-description-setup));
  background: -moz-linear-gradient(var(--gradient-description-setup));
  background: -o-linear-gradient(var(--gradient-description-setup));
  background: linear-gradient(var(--gradient-description-setup));  
}
.gradient-background-description-two {
  background: -ms-linear-gradient(var(--gradient-description-two-setup));  
  background: -webkit-linear-gradient(var(--gradient-description-two-setup));
  background: -moz-linear-gradient(var(--gradient-description-two-setup));
  background: -o-linear-gradient(var(--gradient-description-two-setup));
  background: linear-gradient(var(--gradient-description-two-setup));  
}
.gradient-background-three {
  background: -webkit-linear-gradient(var(--bg-color-one), var(--bg-color-two));
  background: -moz-linear-gradient(var(--bg-color-one), var(--bg-color-two));
  background: -o-linear-gradient(var(--bg-color-one), var(--bg-color-two));
  background: linear-gradient(var(--bg-color-one), var(--bg-color-two));  
}
.gradient-background-three:hover {
  border-color: var(--highlight-border-color);
  box-shadow: inset 0 -2px 12px var(--highlight-border-color), 0 0 var(--highlight-size-one) 3px var(--highlight-border-color);
}
.gradient-background-three-no-hover {
  background: -webkit-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
  background: -moz-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
  background: -o-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
  background: linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));  
}
.gradient-background-four {
  background: -ms-linear-gradient(var(--bg-color-four), var(--bg-color-five));  
  background: -webkit-linear-gradient(var(--bg-color-four), var(--bg-color-five));
  background: -moz-linear-gradient(var(--bg-color-four), var(--bg-color-five));
  background: -o-linear-gradient(var(--bg-color-four), var(--bg-color-five));
  background: linear-gradient(var(--bg-color-four), var(--bg-color-five));  
}

.gradient-background-five {
  background: -ms-linear-gradient(var(--bg-color-five), var(--bg-color-four));
  background: -webkit-linear-gradient(var(--bg-color-five), var(--bg-color-four));
  background: -moz-linear-gradient(var(--bg-color-five), var(--bg-color-four));
  background: -o-linear-gradient(var(--bg-color-five), var(--bg-color-four));
  background: linear-gradient(var(--bg-color-five), var(--bg-color-four));  
}
.gradient-background-six {
  background: -ms-linear-gradient(var(--gradient-six-setup));
  background: -webkit-linear-gradient(var(--gradient-six-setup));
  background: -moz-linear-gradient(var(--gradient-six-setup));
  background: -o-linear-gradient(var(--gradient-six-setup));
  background: linear-gradient(var(--gradient-six-setup));  
}
.gradient-background-six:hover, .gradient-background-twelve:hover, .link:focus, .gradient-background-eleven:hover, .gradient-background-two-hover:hover {
  border-color: var(--highlight-border-color);
  box-shadow: inset 0 0 var(--hover-inset-three) 0 var(--highlight-border-color), 0 0 var(--hover-size) var(--hover-size) var(--highlight-border-color);
}
.gradient-background-eleven {
  background: -ms-linear-gradient(var(--gradient-eleven-setup));
  background: -webkit-linear-gradient(var(--gradient-eleven-setup));
  background: -moz-linear-gradient(var(--gradient-eleven-setup));
  background: -o-linear-gradient(var(--gradient-eleven-setup));
  background: linear-gradient(var(--gradient-eleven-setup));  
}
.gradient-background-eleven-no-hover {
  background: -ms-linear-gradient(var(--gradient-eleven-setup));
  background: -webkit-linear-gradient(var(--gradient-eleven-setup));
  background: -moz-linear-gradient(var(--gradient-eleven-setup));
  background: -o-linear-gradient(var(--gradient-eleven-setup));
  background: linear-gradient(var(--gradient-eleven-setup));  
}
.gradient-background-twelve {
  background: -ms-linear-gradient(var(--gradient-twelve-setup));
  background: -webkit-linear-gradient(var(--gradient-twelve-setup));
  background: -moz-linear-gradient(var(--gradient-twelve-setup));
  background: -o-linear-gradient(var(--gradient-twelve-setup));
  background: linear-gradient(var(--gradient-twelve-setup));  
}
.gradient-background-thirteen {
  background: -ms-linear-gradient(var(--gradient-thirteen-setup));
  background: -webkit-linear-gradient(var(--gradient-thirteen-setup));
  background: -moz-linear-gradient(var(--gradient-thirteen-setup));
  background: -o-linear-gradient(var(--gradient-thirteen-setup));
  background: linear-gradient(var(--gradient-thirteen-setup));  
}
.gradient-background-fourteen {
  background-color: -ms-linear-gradient(var(--gradient-fourteen-setup));
  background-color: -webkit-linear-gradient(var(--gradient-fourteen-setup));
  background-color: -moz-linear-gradient(var(--gradient-fourteen-setup));
  background-color: -o-linear-gradient(var(--gradient-fourteen-setup));
  background-color: linear-gradient(var(--gradient-fourteen-setup));  
}
.gradient-background-seven {
  background: -ms-linear-gradient(var(--gradient-seven-setup));  
  background: -webkit-linear-gradient(var(--gradient-seven-setup));
  background: -moz-linear-gradient(var(--gradient-seven-setup));
  background: -o-linear-gradient(var(--gradient-seven-setup));
  background: linear-gradient(var(--gradient-seven-setup));  
}
.gradient-background-stats {
  background: -ms-linear-gradient(var(--gradient-stats-setup));  
  background: -webkit-linear-gradient(var(--gradient-stats-setup));
  background: -moz-linear-gradient(var(--gradient-stats-setup));
  background: -o-linear-gradient(var(--gradient-stats-setup));
  background: linear-gradient(var(--gradient-stats-setup));  
}
.gradient-background-stats-two {
  background: -ms-linear-gradient(var(--gradient-stats-setup-two));  
  background: -webkit-linear-gradient(var(--gradient-stats-setup-two));
  background: -moz-linear-gradient(var(--gradient-stats-setup-two));
  background: -o-linear-gradient(var(--gradient-stats-setup-two));
  background: linear-gradient(var(--gradient-stats-setup-two));  
}
.gradient-background-eight {
  background: -ms-linear-gradient(#D8D8D8 0%, #fff, #D8D8D8 67%);  
  background: -webkit-linear-gradient(#D8D8D8 0%, #fff, #D8D8D8 67%);
  background: -moz-linear-gradient(#D8D8D8 0%, #fff, #D8D8D8 67%);
  background: -o-linear-gradient(#D8D8D8 0%, #fff, #D8D8D8 67%);
  background: linear-gradient(#D8D8D8 0%, #fff, #D8D8D8 67%);  
}
.gradient-background-nine {
  background: -ms-linear-gradient(var(--gradient-nine-setup));  
  background: -webkit-linear-gradient(var(--gradient-nine-setup));
  background: -moz-linear-gradient(var(--gradient-nine-setup));
  background: -o-linear-gradient(var(--gradient-nine-setup));
  background: linear-gradient(var(--gradient-nine-setup));   
}
.gradient-background-ten {
  background: -ms-linear-gradient(var(--gradient-ten-setup));  
  background: -webkit-linear-gradient(var(--gradient-ten-setup));
  background: -moz-linear-gradient(var(--gradient-ten-setup));
  background: -o-linear-gradient(var(--gradient-ten-setup));
  background: linear-gradient(var(--gradient-ten-setup));  
}
.gradient-background-green {
  background: -ms-linear-gradient(var(--green-button));
  background: -webkit-linear-gradient(var(--green-button));
  background: -moz-linear-gradient(var(--green-button));
  background: -o-linear-gradient(var(--green-button));
  background: linear-gradient(var(--green-button));
}
.gradient-background-red {
  background: -ms-linear-gradient(var(--red-button));
  background: -webkit-linear-gradient(var(--red-button));
  background: -moz-linear-gradient(var(--red-button));
  background: -o-linear-gradient(var(--red-button));
  background: linear-gradient(var(--red-button));  
}
.gradient-background-orange {
  background: -ms-linear-gradient(var(--gradient-six-setup));
  background: -webkit-linear-gradient(var(--orange-button));
  background: -moz-linear-gradient(var(--orange-button));
  background: -o-linear-gradient(var(--orange-button));
  background: linear-gradient(var(--orange-button));
}
.gradient-background-red:hover {
  border-color: var(--bg-red-hover);
  box-shadow: inset 0 0 var(--hover-inset-one) var(--bg-red-hover), 0 0 4px 1px var(--bg-red-hover);
}
.gradient-background-orange:hover {
  border-color: var(--bg-orange-hover);
  box-shadow: inset 0 0 var(--hover-inset-one) var(--bg-orange-hover), 0 0 4px 1px var(--bg-orange-hover);
}
.gradient-background-green:hover {
  border-color: #2ba228;
  box-shadow: inset 0 0 var(--hover-inset-one) #2ba228, 0 0 4px 1px #2ba228;
}
.button-shadow {
  box-shadow:  0px 0px 4px var(--total-member-shadow-size) var(--member-box-shadow);
}
.logo-style {
  text-shadow: 1px 3px 1px var(--logo-color), -1px -1px 1px var(--logo-color), 1px -1px 1px var(--logo-color), -1px 1px 1px var(--logo-color), 1px 1px 1px var(--logo-color);
}
.logo-style-two {
  text-shadow: 1px 3px 1px var(--post-list-color), -1px -1px 1px var(--post-list-color), 1px -1px 1px var(--post-list-color), -1px 1px 1px var(--post-list-color), 1px 1px 1px var(--post-list-color);
}
.logo-style-three {
  text-shadow: 1px 1px 1px var(--post-list-color), -1px -1px 1px var(--post-list-color), 1px -1px 1px var(--post-list-color), -1px 1px 1px var(--post-list-color), 1px 1px 1px var(--post-list-color);
}
.media-shadow {
  box-shadow:  0px 0px var(--image-shadow-size) var(--total-member-shadow-size) var(--member-box-shadow);
}
.preline {
  white-space: pre-line;
}

/* Tooltips */
/* Member Tooltip */
.member-tooltip-large {
  position: relative;
  z-index: 5;
}
.member-tooltip-large::before,
  .member-tooltip-large::after {
    --scale: 0;
    padding-left: 4px;
    padding-right: 4px;
    position: absolute;
    top: -.25rem;
    transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
    transition: .5s transform;
    transform-origin: bottom center;
  }
  .member-tooltip-large::before {
    --translate-y: calc(-100% - var(--arrow-size));
    content: attr(data-tooltip);
    border-radius: .3rem;
    font-size: var(--font-size);
    color: #fff;
    font-family: 'Teko', sans-serif;
    /* text-decoration: underline; */
    text-align: center;
    height: var(--height);
    width: intrinsic;           /* Safari/WebKit uses a non-standard name */
    width: -moz-max-content;    /* Firefox/Gecko */
    width: -webkit-max-content;
    box-shadow:  0px 0px 4px var(--member-shadow-size) var(--member-box-shadow);
    background: -moz-linear-gradient(var(--member-bg-color), var(--member-bg-color-two), var(--member-bg-color));
    background: -o-linear-gradient(var(--member-bg-color), var(--member-bg-color-two), var(--member-bg-color));
    background: linear-gradient(var(--member-bg-color), var(--member-bg-color-two), var(--member-bg-color));
    background: -webkit-linear-gradient(var(--member-bg-color), var(--member-bg-color-two), var(--member-bg-color));
  }
  .member-tooltip-large:hover::before,
  .member-tooltip-large:hover::after {
    --scale: 1;
  }
  .member-tooltip-large::after {
    --translate-y: calc(-1 * var(--arrow-size));
    content: '';
    transform-origin: top center;
  }
  @media (min-width: 763px) {
    .member-tooltip {
      position: relative;
      z-index: 5;
    }
    .member-tooltip::before, 
    .member-tooltip::after {
      --scale: 0;
      padding-left: 4px;
      padding-right: 4px;
      position: absolute;
      top: -.25rem;
      left: 50%;
      transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
      transition: .5s transform;
      transform-origin: bottom center;
    }
    .member-tooltip::before {
      --translate-y: calc(-100% - var(--arrow-size));
      content: attr(data-tooltip);
      border-radius: .3rem;
      font-size: var(--font-size);
      color: #fff;
      font-family: 'Teko', sans-serif;
      /* text-decoration: underline; */
      text-align: center;
      height: var(--height);
      width: intrinsic;           /* Safari/WebKit uses a non-standard name */
      width: -moz-max-content;    /* Firefox/Gecko */
      width: -webkit-max-content;
      box-shadow:  0px 0px 4px var(--member-shadow-size) var(--member-box-shadow);
      background: -moz-linear-gradient(var(--member-bg-color), var(--member-bg-color-two), var(--member-bg-color));
      background: -o-linear-gradient(var(--member-bg-color), var(--member-bg-color-two), var(--member-bg-color));
      background: linear-gradient(var(--member-bg-color), var(--member-bg-color-two), var(--member-bg-color));
      background: -webkit-linear-gradient(var(--member-bg-color), var(--member-bg-color-two), var(--member-bg-color));
    }
    .member-tooltip:hover::before,
    .member-tooltip:hover::after {
      --scale: 1;
    }
    .member-tooltip::after {
      --translate-y: calc(-1 * var(--arrow-size));
      content: '';
      transform-origin: top center;
    }
  }
  /* Thumb Tooltip */
  .thumb-tooltip {
    position: relative;
    z-index: 5;
  }
  .thumb-tooltip::before,
  .thumb-tooltip::after {
    --scale: 0;
    --arrows-size: 10px;
    position: absolute;
    top: -.25rem;
    left: 50%;
    transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
    border: 1px;
    border-color: white;
  }
  .thumb-tooltip::before {
    --translate-y: calc(-100% - var(--arrows-size));
    content: attr(data-tooltip);
    color: var(--tooltip-font-color);
    padding: 6px;
    border-radius: .3rem;
    font-size: 13px;
    text-align: center;
    height: auto;
    width: auto;
    margin-left: 20px;
    box-shadow:  0px 0px 4px 1px var(--member-box-shadow);
    background: -moz-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
    background: -o-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
    background: linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
    background: -webkit-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
  }
  /* Media queries so the thumbs tooltip isn't cut off on any screen size */
  @media (min-width: 640px) { 
    .thumb-tooltip::before { width: auto; margin-left: 8px;} 
  }
  @media (min-width: 863px) { 
    .thumb-tooltip::before { 
      width: intrinsic;           /* Safari/WebKit uses a non-standard name */
      width: -moz-max-content;    /* Firefox/Gecko */
      width: -webkit-max-content; 
      margin-left: 0px;
    } 
  }
  @media (min-width: 914px) { .thumb-tooltip::before { width: auto; margin-left: 4px; } }
  @media (min-width: 976px) { 
    .thumb-tooltip::before { 
      width: intrinsic;           /* Safari/WebKit uses a non-standard name */
      width: -moz-max-content;    /* Firefox/Gecko */
      width: -webkit-max-content; 
      margin-left: 0px;
    } 
  }  
  .thumb-tooltip:hover::before,
  .thumb-tooltip:hover::after {
    --scale: 1;
  }
  .thumb-tooltip::after {
    --translate-y: calc(-1 * var(--arrows-size));
    content: '';
    transform-origin: top center;
  }

  .tooltip, .stampshot-tooltip, .bottom-tooltip {
    position: relative;
    z-index: 5;
  }
  .tooltip::before,
  .tooltip::after, .stampshot-tooltip::before,
  .stampshot-tooltip::after {
    --scale: 0;
    --arrows-size: 10px;
    position: absolute;
    top: -.25rem;
    left: 50%;
    transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
    border: 1px;
    border-color: white;
  }

  .tooltip::before {
    --translate-y: calc(-100% - var(--arrows-size));
    content: attr(data-tooltip);
    color: var(--tooltip-font-color);
    padding: 6px;
    border-radius: .3rem;
    font-size: 15px;
    font-family: 'Open-Sans sans-serif';
    text-align: center;
    height: auto;
    width: intrinsic;           /* Safari/WebKit uses a non-standard name */
  width: -moz-max-content;    /* Firefox/Gecko */
  width: -webkit-max-content;
    box-shadow:  0px 0px 4px 1px var(--member-box-shadow);
    background: -moz-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
    background: -o-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
    background: linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
    background: -webkit-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
  }

  .tooltip:hover::before,
  .tooltip:hover::after, 
  .stampshot-tooltip:hover::before,
  .stampshot-tooltip:hover::after,
  .bottom-tooltip:hover::before,
  .bottom-tooltip:hover::after {
    --scale: 1;
  }
  .tooltip::after, .stampshot-tooltip::after {
    --translate-y: calc(-1 * var(--arrows-size));
    content: '';
    transform-origin: top center;
    /* border: var(--arrows-size) outset transparent;
    border-top-color: #D8D8D8; */
  }

  .stampshot-tooltip::before {
    --translate-y: calc(-100% - var(--arrows-size));
    content: attr(data-tooltip);
    color: var(--tooltip-font-color);
    padding: 6px;
    border-radius: 8px;
    font-size: 15px;
    font-family: 'Open-Sans sans-serif';
    text-align: center;
    height: auto;
    width: 175px;
    box-shadow:  0px 0px 4px 1px var(--member-box-shadow);
    background: -moz-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
    background: -o-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
    background: linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
    background: -webkit-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
  }
  .bottom-tooltip::after {
    --translate-y: calc(-1 * var(--arrows-size));
    content: '';
    transform-origin: top center;
  }
  .bottom-tooltip::before {
    --translate-y: calc(55% - var(--arrows-size));
    content: attr(data-tooltip);
    color: var(--tooltip-font-color);
    padding: 6px;
    border-radius: .3rem;
    font-size: 15px;
    font-family: 'Open-Sans sans-serif';
    text-align: center;
    height: auto;
    width: 200px;
    box-shadow:  0px 0px 4px 1px var(--member-box-shadow);
    background: -moz-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
    background: -o-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
    background: linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
    background: -webkit-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
  }
  .bottom-tooltip::before,
  .bottom-tooltip::after {
    --scale: 0;
    --arrows-size: 10px;
    position: absolute;
    top: -.25rem;
    left: -6rem;
    transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
    transition: 150ms transform;
    transform-origin: top right;
    border: 1px;
    border-color: white;
  }

/* Transitions */
.transition-to-opacity {
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  /* transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;
  -webkit-transition-property: opacity;
  -webkit-transition-duration: 1s;
  -webkit-transition-timing-function: ease-in-out;
  -moz-transition-property: opacity;
  -moz-transition-duration: 1s;
  -moz-transition-timing-function: ease-in-out; */
}
.transition {
  transition-property: background-color, color;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
}
.category-hover {
  transition-property: background-color, color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}
.adding-comment {
  height: 0;
  overflow-y: hidden;
  overflow-x: hidden;
}
.adding-comment.expanded {
  height: auto;
  margin-bottom: 10px;
}
/* For the paywall dollar sign */
.paywall-dollar-sign {
  position:relative;
}
.paywall-dollar-sign:after {
  position: absolute;
  left: 6px;
  top: 1px;
  content: '$';
}
/* For the light/dark mode icon */
.checkbox {
  opacity: 0;
  position: absolute;
}
/* Icons for light/dark mode */
.moon {
  color: #f1c40f;
  padding-left: 2px;
}
.sun {
  color: #f39c12;
  padding-right: 1px;
}
/* For the Spinner Icon */
.multi-spinner-container {
  width: 150px;
  height: 150px;
  position: relative;
  margin: 10px auto;
  overflow: hidden;
}
.multi-spinner {
  position: absolute;
  width: calc(100% - 9.9px);
  height: calc(100% - 9.9px);
  border: 5px solid transparent;
  border-top-color: #2BA228;
  border-radius: 50%;
  -webkit-animation: spin 3s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
  animation: spin 3s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
}
.multi-spinner-two {
  position: absolute;
  width: calc(100% - 9.9px);
  height: calc(100% - 9.9px);
  border: 5px solid transparent;
  border-top-color: #000000;
  border-radius: 50%;
  -webkit-animation: spin 3s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
  animation: spin 3s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
}
/* Stamp */
.stamp {
  display: none;
}
@media (min-width: 450px) {
  .stamp {
    display: block;
    width: 55px;
    height: 40px;
    padding-top: 11px;
    padding-right: 1px;
    position: relative;
    -webkit-filter: drop-shadow(-1px 1px 2px #030303);
    /*The stamp cutout will be created using crisp radial gradients*/
    background: radial-gradient(
      transparent 6px, 
      transparent 4px, 
      #fff 4px,
      #fff
    );
    /*reducing the gradient size*/
    background-size: 15px 15px;
    /*Offset to move the holes to the edge*/
    background-position: -10px -10px;
    transform: rotate(-10deg);
    color:black;
    font-weight: bold;
    text-shadow: none;
  }
  
  .stamp:after {
    content: '';
    position: absolute;
    left: 5px; top: 5px; right: 5px; bottom: 5px;
    /*Shadow - doesn't look good because of the stamp cutout. We can still move this into another pseudo element behind the .stamp main element*/
    background-color: #fff;
    box-shadow: inset 0 0 8px #2e2e2e;
    border-radius: 6px;
    /*pushing it back*/
    z-index: -1;
  }
  .stamp:before {
    content: '';
    position: absolute;
    bottom: 0; left: 0;
  }
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 25px;
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}
.list-body > :first-child.i-container, 
.list-body > :first-child.instagram-container, 
.list-body > :first-child.facebook-container,
.list-body > :first-child.tiktok-container,
.list-body > :first-child.invis-container
{
  margin-top: 31px;
}
.line-clamp.list-body > :first-child.i-container,
.line-clamp.list-body > :first-child.instagram-container,
.line-clamp.list-body > :first-child.facebook-container,
.line-clamp.list-body > :first-child.tiktok-container,
.line-clamp.list-body > :first-child.invis-container
{
  margin-top: 0px;
}
.line-clamp.list-body > :first-child.tiktok-container {
  margin-left: 0;
  margin-right: 0;
  min-width: auto;
}
/* 410px for tiktok-container */
/* Toastify styles */
.Toastify__toast {
  border-radius: 14px;
  min-height: 44px;
}
.Toastify__toast--success {
  --green-toast: #8cff8b, #2ba228 40%;
  background: -ms-linear-gradient(var(--green-toast));
  background: -webkit-linear-gradient(var(--green-toast));
  background: -moz-linear-gradient(var(--green-toast));
  background: -o-linear-gradient(var(--green-toast));
  background: linear-gradient(var(--green-toast));  
  border: 1px solid #5E5E5E;
  box-shadow: -1px -1px 4px 0px #5E5E5E;
}
.Toastify__progress-bar {
  background-color: white;
  background-image:
  linear-gradient(
    white, #d8d8d8 40%
  );
}
.Toastify__toast--error {
  --red-toast: #ffa0a0, #c53030 40%;
  background: -ms-linear-gradient(var(--red-toast));
  background: -webkit-linear-gradient(var(--red-toast));
  background: -moz-linear-gradient(var(--red-toast));
  background: -o-linear-gradient(var(--red-toast));
  background: linear-gradient(var(--red-toast));  
  border: 1px solid #5E5E5E;
  box-shadow: -1px -1px 4px 0px #5E5E5E;
}
/* react-modal */
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  z-index: 6;
}
.ReactModal__Overlay--after-open{
  opacity: 1;
}
.ReactModal__Overlay--before-close{
  opacity: 0;
}
/* HandCash Connect */
.connectButton, .connectButtonNoImage {
	-webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
	font-family: 'Poppins', sans-serif;
  box-shadow: 0 0 3px 1px var(--highlight-border-color);
	background-image: linear-gradient(#38CB7C 7%, #1CB462 14%);
	border-radius: 8px;
	display: inline-block;
	text-align: center;
	cursor: pointer;
	color: #ffffff;
	font-size: 16px;
	font-weight: 500;
	padding: 4px 8px;
	text-decoration: none;
	transition: 0.3s;
	vertical-align: middle;
	letter-spacing: 0.5px;
}
.connectButton:before {
	/* background: url('/images/handcash.jpg') no-repeat scroll center center / 100% auto rgba(0, 0, 0, 0); */
  content: "";
  display: inline-block;
  color: #fff;
  height: 20px;
  margin-right: 4px;
  margin-bottom: 1px;
  position: relative;
  vertical-align: middle;
  width: 20px;
}
.img-fluid {
  text-align: center
}
.connectButton:hover, .connectButtonNoImage:hover {
	background-image: linear-gradient(#31C475 7%, #16B15D 14%);
	top: 1px;
  box-shadow: 0 0 5px 1px var(--highlight-border-color);
}
.connectButton:active, .connectButtonNoImage:active {
	background-image: linear-gradient(#38CB7C 7%, #1CB462 14%);
	position: relative;
	top: 1px;
  box-shadow: 0 0 5px 1px var(--highlight-border-color);
}

/* For expanding postList Description */
[data-expandable] [data-expand-text] {
  --line-height: 1.5;
  --lines-to-show: 1;
  box-sizing: border-box;
  line-height: var(--line-height);
  overflow: hidden;
  height: calc(var(--line-height) * var(--lines-to-show) * 1em);
}
[data-overflow="false"] [data-expand-text] {
  height: initial;
}
[data-expandable].expanded [data-expand-text] {
  height: initial;
}
[data-overflow="false"] [data-expand-button] {
  display: none;
}
[data-expandable] [data-expand-profiletext] {
  --line-height: 1.5;
  --lines-to-show: 5;
  box-sizing: border-box;
  line-height: var(--line-height);
  overflow: hidden;
  height: calc(var(--line-height) * var(--lines-to-show) * 1em);
}
[data-overflow="false"] [data-expand-profiletext] {
  height: initial;
}
[data-expandable].expanded [data-expand-profiletext] {
  height: initial;
}
[data-overflow="false"] [data-expand-button] {
  display: none;
}
:root {
  --list-lines: 4.5;
}
@media (min-width: 600px) {
  :root {
    --list-lines: 2.2;
  }
}
[data-expandable] [data-expand-list-comment] {
  --line-height: 1.5;
  --lines-to-show: var(--list-lines);
  box-sizing: border-box;
  line-height: var(--line-height);
  overflow: hidden;
  height: calc(var(--line-height) * var(--lines-to-show) * 1em);
}
[data-overflow="false"] [data-expand-list-comment] {
  height: initial;
}
[data-expandable].expanded [data-expand-list-comment] {
  height: initial;
}

.ellipsis {
  text-overflow: ellipsis;
}
/* For the CKEditor */
ul {
  margin-left: 1em;
  list-style-type: square;
}
ol {
  margin-left: 1em;
  list-style-type: decimal;
}
li {
  margin-left: 1em;
  font-size: 16px
}
textarea::-webkit-input-placeholder, textarea:-moz-placeholder, textarea::-moz-placeholder, textarea:-ms-input-placeholder, textarea::placeholder, input::placeholder, input::-webkit-input-placeholder, input:-moz-placeholder, input::-moz-placeholder, input:-ms-input-placeholder, input::-ms-input-placeholder {
  color: #5E5E5E;
  opacity: 1;
}
textarea {
  cursor: auto;
}
.inner-shadow {
  box-shadow: inset 0 0 5px var(inner-shadow);
}
pre, p, ul, li, ol, h1, h2, h3, h4, h5, blockquote, a, figcaption {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
 }
pre {
  /* needs to have a different color */
  background-color: #000000;
  color: #121212;
  padding: 12px;
  font-size: 16px;
  border: 1px solid gray
}
code {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  background-color: rgb(222,222,222);
  padding: 1px;
  color: #1b1b1b;
}
 /* This makes it so only <a>'s with a <p> tag have this property (so in posts it'll highlight for the user) */
/* main p a:hover {
  color: var(--bg-hover);
  font-weight: bold;
} */
.notification-comment p {
  font-size: 14px;
}
main p a, main ul li a, main ol li a, .is-link, .parsed-editor-data a  {
  text-decoration: underline;
  text-underline-position: under;
  color: var(--href-link);
  font-weight: bold;
  cursor: pointer;
}
.is-link-no-underline {
  text-underline-position: under;
  color: var(--href-link);
  cursor: pointer;
  font-weight: bold;
}
.is-link-no-underline:hover {
  font-weight: bold;
}
/* No longer using */
/* .is-link-no-color {
  text-underline-position: under;
  cursor: pointer;
}
.is-link-no-color:hover {
  font-weight: bold;
} */
figcaption {
  text-align: center;
  font-size: 16px;
  margin: .3em;
  font-style: italic;
}
p {
  font-size: 16px;
}
.parsed-editor-data .ql-indent-1 {
  margin-left: 2em;
}
.parsed-editor-data .ql-indent-2 {
  margin-left: 3em;
}
.parsed-editor-data .ql-indent-3 {
  margin-left: 4em;
}
.parsed-editor-data .ql-indent-4 {
  margin-left: 5em;
}
.parsed-editor-data .ql-indent-5 {
  margin-left: 6em;
}
.parsed-editor-data .ql-indent-6 {
  margin-left: 7em;
}
.parsed-editor-data .ql-indent-7 {
  margin-left: 8em;
}
.parsed-editor-data .ql-indent-8 {
  margin-left: 9em;
}
.profile-comment p, .profile-comment li, .profile-comment ul {
  font-size: 14px;
}
.ql-editor {
  overflow: hidden;
}
.ql-editor li {
  margin-left: -1em;
}
.ql-editor .ql-indent-1 {
  margin-left: -2em;
}
.ql-editor .ql-indent-2 {
  margin-left: -3em;
}
.ql-editor .ql-indent-3 {
  margin-left: -4em;
}
.ql-editor .ql-indent-4 {
  margin-left: -5em;
}
.ql-editor .ql-indent-5 {
  margin-left: -6em;
}
.ql-editor .ql-indent-6 {
  margin-left: -7em;
}
.ql-editor .ql-indent-7 {
  margin-left: -8em;
}
.ql-editor .ql-indent-8 {
  margin-left: -9em;
}
blockquote {
  border-left: 4px solid;
  margin: 0.25em 10px;
  padding: 0.25em 10px;
  font-style: italic;
  border-color: #d3d3d3;
  font-size: 14px;
}
blockquote p {
  font-size: 14px;
}
blockquote p i {
  font-size: 14px;
  /* font-style: normal; */
  border-bottom: 1px solid #ccc; 
}
/* Ying Yang stuff */
.yingyang-circle {
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  padding-left: 8px;
  background-image: linear-gradient(to 
    left, #fff, #fff 50%, #000 50%, #000);
  animation: roll 10s linear infinite;
}
.yinyang {
  position: relative;
  height: 16px;
  width: 16px; 
  border-radius: 50%;
  background-image: linear-gradient(
    to left, #fff, #fff 50%, #000 50%, #000);
  animation: roll 4s linear infinite;
  animation-direction: reverse;
} 
.yinyang:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translatex(-50%);
  background: #fff;
  border: 2px solid #000;
  border-radius: 50%;
  box-sizing: initial;
  width: 4px;
  height: 4px;
 }
.yinyang:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translatex(-50%);
  background: #000;
  border: 2px solid #fff;
  border-radius: 50%;
  box-sizing: initial;
  width: 4px;
  height: 4px;
}
@keyframes roll {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(-360deg);
  }
}
.label {
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  position: relative;
  height: 40px;
  width: 69px;
}
.label .ball {
  border-radius: 50%;
  position: absolute;
  transition: transform 0.3s linear;
}
.checkbox:checked + .label .ball {
  transform: translateX(28px);
}
.slide {
  opacity: 0;
  inset: 0;
  position: absolute;
  z-index: 0;
  transition: 1.5s;
  transform: translateX(200%);
}
.slide[data-active] {
  opacity: 1;
  z-index: 2;
  -webkit-transition: opacity 1.5s;
  transition: opacity 1.5s;
  transition-timing-function: ease-in;
  transform: translateX(0%);
  position: absolute;
}
/* Checkbox for ProfileInputModal*/
.checkbox-2 { display: none; }
.checkbox-2 + label {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  width: fit-content;

}
.ml-36px {
  margin-left: 36px;
}
.checkbox-2 + label:before {
  content: '';
  display: block;
  width: 26px;
  height: 24px;
  border: 1px solid var(--inner-border-color);
  border-radius: 8px;
  box-shadow: 0px 0px 3px 0px var(--inner-border-color);
  position: absolute;
  left: 0;
  top: -1px;
  opacity: .6;
  -webkit-transition: all .12s, border-color .08s;
  transition: all .12s, border-color .08s;
  background: -webkit-linear-gradient(var(--bg-color-one), var(--bg-color-two));
  background: -moz-linear-gradient(var(--bg-color-one), var(--bg-color-two));
  background: -o-linear-gradient(var(--bg-color-one), var(--bg-color-two));
  background: linear-gradient(var(--bg-color-one), var(--bg-color-two));  
}
.checkbox-2:checked + label:before {
  width: 10px;
  top: -5px;
  left: 8px;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: #2ba228;
  border-bottom-color: #2ba228;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background: transparent;
  box-shadow: none;
}
/* Dropdown */
.dropdown {
  position: relative;
}
.dropdown-top-nav {
  top: calc(100% + .50rem);
}
.dropdown-menu {
  position: absolute;
  /* right: 0; */
  /* top: calc(100% + .50rem); */
  list-style-type: none;
  opacity: 0;
  pointer-events: none;
  color: var(--tooltip-font-color);
  /* background: -webkit-linear-gradient(var(--bg-color-one-rc), var(--bg-color-two-rc));
  background: -moz-linear-gradient(var(--bg-color-one-rc), var(--bg-color-two-rc));
  background: -o-linear-gradient(var(--bg-color-one-rc), var(--bg-color-two-rc));
  background: linear-gradient(var(--bg-color-one-rc), var(--bg-color-two-rc));   */
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
  transform: translateY(-10px);
  z-index: 7;
  background: var(--dropdown-background)
}
.nav-accordion-margin-top {
  margin-top: 16px;
}
.menu-big-shadow {
  box-shadow: 0px 0px var(--big-shadow) var(--total-member-shadow-size) var(--member-box-shadow);
}
/* Mobile screen size changes to classes */
@media (min-width: 640px) {
  .nav-accordion-margin-top {
    margin-top: 2px;
  }
  .menu-big-shadow {
    box-shadow: 0px 0px var(--menu-big-shadow) var(--total-member-shadow-size) var(--member-box-shadow);
  }
}
.big-shadow {
  box-shadow: 0px 0px var(--big-shadow) var(--total-member-shadow-size) var(--member-box-shadow);
}
.dropdown.active > .link + .dropdown-menu {
  pointer-events: auto;
  opacity: 1;
  transform: translateY(0);
}
.accordion-content, .accordion-content-faq {
  max-height: 0px;
  overflow: hidden;
  /* transition: max-height 500ms cubic-bezier(0,1,0,1); */
  transition: max-height 500ms ease 0s;
}
.accordion-content.show {
  height: auto;
  max-height: 600px;
  transition: max-height 1000ms ease 0s;
}
.accordion-content-faq.show {
  height: auto;
  max-height: 1098px;
  /* transition: max-height 500ms cubic-bezier(1,0,1,0); */
  transition: max-height 1000ms ease 0s;
}

/* Quill */
.ql-snow .ql-picker.ql-expanded .ql-picker-options, .ql-bubble .ql-picker.ql-expanded .ql-picker-options {
  background: -ms-linear-gradient(var(--gradient-seven-setup));  
  background: -webkit-linear-gradient(var(--gradient-seven-setup));
  background: -moz-linear-gradient(var(--gradient-seven-setup));
  background: -o-linear-gradient(var(--gradient-seven-setup));
  background: linear-gradient(var(--gradient-seven-setup));  
}
.ql-clipboard {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 0px; 
}
.ql-bubble .ql-picker.ql-expanded .ql-picker-options {
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 20%) 0 2px 8px;
  margin-top: 3px;
}
/* Header Dropdown */
.ql-toolbar.ql-snow .ql-picker-label, .ql-toolbar.ql-bubble .ql-picker-label {
  border: none !important;
}
/* Header Dropdown */
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options, .ql-toolbar.ql-bubble .ql-picker.ql-expanded .ql-picker-options {
  border-radius: 1.25rem;
  border: none;
  margin-top: 5px;
  width: 147px;
}
/* Text color that actually prints out. Probably background change too */
.ql-container {
  color: #fff !important;
}
/* Colors */
.ql-picker-label {
  padding-left: 5px !important; 
  color: var(--editor-font-color);
}
.ql-toolbar .ql-stroke {
  fill: none;
  stroke: var(--editor-font-color);
}
.ql-toolbar .ql-fill {
  fill: var(--editor-font-color);
}
/* This is for the Normal/Header1/Header2 when it's getting selected. It acts goofy */
.ql-snow .ql-picker.ql-expanded .ql-picker-label, .ql-bubble .ql-picker.ql-expanded .ql-picker-label {
  color: var(--highlight-border-color);
}
/* Placeholder */
.ql-editor.ql-blank::before {
  color: #5E5E5E;
  font-size: 14px;
}
.ql-tooltip {
  white-space: normal !important;
  z-index: 2;
}
.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
  border-bottom: transparent
}
.quill {
  box-shadow: 0px 0px 4px 1px #1b1b1b;
  border-radius: 1.25rem;
}
.ql-snow .ql-tooltip {
  box-shadow: 0px 0px 3px 0px #1b1b1b;
}
.ql-snow a, .ql-bubble a {
  color: #1b1b1b;
}
.ql-snow a:hover, .ql-bubble a:hover {
  color: var(--bg-hover);
  font-weight: bold;
}
.ql-toolbar {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  border-bottom-left-radius: var(--border-radius-two);
  border-bottom-right-radius: var(--border-radius-two);
  background: -ms-linear-gradient(var(--bg-color-four), var(--bg-color-five));  
  background: -webkit-linear-gradient(var(--bg-color-four), var(--bg-color-five));
  background: -moz-linear-gradient(var(--bg-color-four), var(--bg-color-five));
  background: -o-linear-gradient(var(--bg-color-four), var(--bg-color-five));
  background: linear-gradient(var(--bg-color-four), var(--bg-color-five));  
  border-color: var(--quill-border-color) !important;
  box-shadow: 0 0 1px 0 black;
}
.ql-bubble .ql-tooltip-editor input[type=text] {
  color: var(--member-box-shadow);
}
.ql-container.ql-snow, .ql-container.ql-bubble {
  border: none;
}
.ql-tooltip {
  left: 8px !important;
  margin-right: 8px;
  background: -ms-linear-gradient(var(--gradient-seven-setup));  
  background: -webkit-linear-gradient(var(--gradient-seven-setup));
  background: -moz-linear-gradient(var(--gradient-seven-setup));
  background: -o-linear-gradient(var(--gradient-seven-setup));
  background: linear-gradient(var(--gradient-seven-setup));  
}
/* Gives the box shadow on the inside */
.ql-editor:focus-visible {
  box-shadow: inset 0 0 var(--highlight-size-three) black;
  border: 1px solid var(--focus-border);
  border-bottom-right-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  border-top-right-radius: var(--border-radius-two);
  border-top-left-radius: var(--border-radius-two);
}
.ql-snow .ql-tooltip.ql-editing input[type=text]:focus-visible, .ql-bubble .ql-tooltip.ql-editing input[type=text]:focus-visible {
  box-shadow: inset 0 0 var(--highlight-size-three) black;
  border: 1px solid var(--focus-border);
  border-bottom-right-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  border-top-left-radius: 1.25rem;
}
.ql-snow .ql-tooltip.ql-editing input[type=text]:focus, .ql-bubble .ql-tooltip.ql-editing input[type=text]:focus {
  outline-color: var(--bg-color-eight);
  outline-width: 1px;
}
.ql-editing {
  z-index: 100;
}
/* Changing tooltip text */
/* .ql-snow .ql-tooltip[data-mode=link]::before {
  content: "Enter link:";
} */
.ql-snow .ql-tooltip[data-mode=video]::before {
  content: "Video Link:";
}
/* Editor Styling */
.ql-editor {
  color: #1a202c !important;
  min-height: 140px;
  border-bottom-right-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  border-top-right-radius: var(--border-radius-two);
  border-top-left-radius: var(--border-radius-two);
  border-width: 1px;
  border-color: transparent;
  background: -ms-linear-gradient(var(--gradient-eight-setup));  
  background: -webkit-linear-gradient(var(--gradient-eight-setup));
  background: -moz-linear-gradient(var(--gradient-eight-setup));
  background: -o-linear-gradient(var(--gradient-eight-setup));
  background: linear-gradient(var(--gradient-eight-setup))
}
/* Container bottom borders */
.ql-container {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  font-family: 'Montserrat',
    'system-ui',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    '"Noto Sans"',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
    '"Noto Color Emoji"';
}
.ql-snow .ql-editor h1, .parsed-editor-data h1 {
  font-size: 1.75rem;
}
.ql-snow .ql-editor h2, .parsed-editor-data h2 {
  font-size: 1.5rem;
}
.ql-align-center {
  text-align: center;
}
.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected,
.ql-bubble.ql-toolbar button:hover,
.ql-bubble .ql-toolbar button:hover,
.ql-bubble.ql-toolbar button:focus,
.ql-bubble .ql-toolbar button:focus,
.ql-bubble.ql-toolbar button.ql-active,
.ql-bubble .ql-toolbar button.ql-active,
.ql-bubble.ql-toolbar .ql-picker-label:hover,
.ql-bubble .ql-toolbar .ql-picker-label:hover,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active,
.ql-bubble.ql-toolbar .ql-picker-item:hover,
.ql-bubble .ql-toolbar .ql-picker-item:hover,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--highlight-border-color) !important;
  font-weight: var(--bold-or-not);
  border-radius: 1.25rem;
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar button:hover .ql-fill,
.ql-bubble .ql-toolbar button:hover .ql-fill,
.ql-bubble.ql-toolbar button:focus .ql-fill,
.ql-bubble .ql-toolbar button:focus .ql-fill,
.ql-bubble.ql-toolbar button.ql-active .ql-fill,
.ql-bubble .ql-toolbar button.ql-active .ql-fill,
.ql-bubble.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-bubble .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-bubble.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-bubble .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-bubble.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: var(--highlight-border-color) !important;
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-bubble.ql-toolbar button:hover .ql-stroke,
.ql-bubble .ql-toolbar button:hover .ql-stroke,
.ql-bubble.ql-toolbar button:focus .ql-stroke,
.ql-bubble .ql-toolbar button:focus .ql-stroke,
.ql-bubble.ql-toolbar button.ql-active .ql-stroke,
.ql-bubble .ql-toolbar button.ql-active .ql-stroke,
.ql-bubble.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-bubble.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-bubble.ql-toolbar button:hover .ql-stroke-miter,
.ql-bubble .ql-toolbar button:hover .ql-stroke-miter,
.ql-bubble.ql-toolbar button:focus .ql-stroke-miter,
.ql-bubble .ql-toolbar button:focus .ql-stroke-miter,
.ql-bubble.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-bubble .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-bubble.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-bubble .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-bubble.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-bubble .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-bubble.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-bubble .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-bubble.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-bubble .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--highlight-border-color) !important;

}
.ql-bubble .ql-picker, .ql-snow .ql-picker {
  color: black;
}

/** All toolbar buttons are inside of .ql-formats */
.ql-bold, .ql-italic, .ql-underline, .ql-blockquote, .ql-code, .ql-list, .ql-indent, .ql-align,
.ql-link, .ql-image, .ql-video, .ql-clean {
  position: relative;
  z-index: 5;
}
.ql-bold::before {content: 'Bold';}
.ql-italic::before {content: 'Italic';} 
.ql-underline::before {content: 'Underline';} 
.ql-blockquote::before {content: 'Blockquote';} 
.ql-code::before {content: 'Code';} 
.ql-list[value="ordered"]::before {content: 'Ordered List';} 
.ql-list[value="bullet"]::before {content: 'Bullet List';} 
.ql-indent[value="-1"]::before {content: 'Back Indent';} 
.ql-indent[value="+1"]::before {content: 'Add Indent';} 
/* [class*="ql-align"][data-value="center"] */
.ql-align::before {content: 'Align Left';} 
.ql-align[value="center"]::before {content: 'Align Center';} 
.ql-link::before{content: 'Link';}
.ql-image::before {content: 'Image';}
.ql-video::before {content: 'Media';}
.ql-clean::before {content: 'Clear';}

.ql-bold::before, .ql-bold::after, .ql-italic::before, .ql-italic::after,  
.ql-underline::before, .ql-underline::after, .ql-blockquote::before, .ql-blockquote::after,
.ql-code::before, .ql-code::after, .ql-list::before, .ql-list::after, .ql-indent::before, .ql-indent::after,
.ql-align::before, .ql-align::after, .ql-link::before, .ql-link::after, .ql-image::before, .ql-image::after,
.ql-video::before, .ql-video::after, .ql-clean::before, .ql-clean::after
{
  --scale: 0;
  --arrows-size: 10px;
  position: absolute;
  top: -.25rem;
  left: 50%;
  transform: translateX(-50%) translateY(var(--translate-y, 0)) scale(var(--scale));
  transition: 150ms transform;
  transform-origin: bottom center;
}
.ql-bold::before, .ql-italic::before, .ql-underline::before, .ql-blockquote::before,
.ql-code::before, .ql-list::before, .ql-indent::before, .ql-align::before, .ql-link::before, 
.ql-image::before, .ql-video::before, .ql-clean::before {
  --translate-y: calc(-100% - 8px);
  color: var(--tooltip-font-color);
  padding: 6px;
  border-radius: .3rem;
  font-size: 12px;
  text-align: center;
  height: auto;
  width: intrinsic;           /* Safari/WebKit uses a non-standard name */
  width: -moz-max-content;    /* Firefox/Gecko */
  width: -webkit-max-content;
  box-shadow:  0px 0px 3px 0px var(--member-box-shadow);
  background: -moz-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
  background: -o-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
  background: linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
  background: -webkit-linear-gradient(var(--total-member-bg-color-one), var(--total-member-bg-color-two), var(--total-member-bg-color-one));
}

.ql-bold:hover::before, .ql-bold:hover::after, .ql-italic:hover::before, .ql-italic:hover::after, 
.ql-underline:hover::before, .ql-underline:hover::after, .ql-blockquote:hover::before, .ql-blockquote:hover::after,
.ql-code:hover::before, .ql-code:hover::after, .ql-list:hover::before, .ql-list:hover::after,
.ql-indent:hover::before, .ql-indent:hover::after, .ql-align:hover::before, .ql-align:hover::after,
.ql-link:hover::before, .ql-link:hover::after, .ql-image:hover::before, .ql-image:hover::after,
.ql-video:hover::before, .ql-video:hover::after, .ql-clean:hover::before, .ql-clean:hover::after                  
{
  --scale: 1;
}
.ql-bold::after, .ql-italic::after, .ql-underline::after, .ql-blockquote::after, .ql-code::after,
.ql-list::after, .ql-indent::after, .ql-align::after, .ql-link::after, .ql-image::after, .ql-video::after, .ql-clean::after
{
  --translate-y: calc(-1 * 8px);
  content: '';
  transform-origin: top center;
  border: 6px outset transparent;
  border-top-color: var(--total-member-bg-color-one);
  margin-top: -1px;
}

/* Keeping this below code in case I do take a stab at the unlock animation,
   but for now, I've aborted. It's not high enough quality if it's a big giant animation
   that takes up the page. And I don't think the animation will be noticeable or worthwhile enough if it's small
   So, no unlocking animation at this time. Not until it can be done right. Waste of a handful of hours... */
/* 
.center-screen { 
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.lock {
position: relative;
width: 200px;
height: 150px;
background-color: #121212;
display: flex;
justify-content: center;
flex-direction: column;
border-radius: 20px;
border: 5px solid #fff;
transition: all 1.5s;
transition-delay: .5s;
}
.lock .key {
  width: 25px;
  height: 15px;
  background-color: #fff;
  margin: 10px auto 0;
  border-radius: 15px;
  transition: all .1s;
} 
.lock::before {
  content: '';
  width: 120px;
  height: 80px;
  background: transparent;
  border: 8px solid #fff;
  border-bottom: 0;
  border-radius: 25px 25px 0 0;
  position: absolute;
  top: -85px;
  left: 35px;
  transition-delay: 2s;
  transition-property: all;
}
.unlock {
  border: 5px solid #2ba228 !important;
  transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
}
.unlock::before {
  border-color: #2ba228 !important;
  border-left-color: transparent !important;
  top: -100px;
  width: 120px;
  height: 99px;
  
}
.unlock::after {
  top: -94px;
  left: 40px;
  height: 50px;
  border: 4px solid #2ba228;
  border-radius: 300px 62px 289px 50px;
  transition-delay: 2s;
  transition-property: all;
}
.lock::after {
  content: '';
  position: absolute;
  top: -94px;
  left: 40px;
  height: 50px;
}

.unlock .key {
  transform: rotate(-90deg);
  background-color: #2ba228;
} */